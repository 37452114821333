/**
 * @description 导出网络配置
 **/
module.exports = {
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'https://nuoche.yuanzhongkeji.cn/api'
      : 'https://nuoche.yuanzhongkeji.cn/api',
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 30000,
}
